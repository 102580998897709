import React from 'react';

function CloseIcon({stroke}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.4895 1.98975L13.4895 13.9897" stroke={stroke || "#151515"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.6567 2L7.99988 7.65685" stroke={stroke || "#151515" } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.65649 14L7.65649 8" stroke={stroke || "#151515" } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default CloseIcon;