import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ukApiHelper } from '../../helper/apiHelper';
import parse from "html-react-parser";
import Dropdown from 'react-bootstrap/Dropdown';

// scss
import styles from "./footer.module.scss";

import paymentOption from "../../public/paymentOption.svg";
import stripImg from "../../public/stripImg.svg";
import dmca from "../../public/dmca_protected_sml_120m.webp";
import copyrightImg from "../../public/copyright_image.webp";
import CloseIcon from "../icons/closeIcon";

import Image from 'next/image';
import {
  clientAreaLinks,
  generalLinks,
  policyLinks,
  socialLinks,
  topServicesLinks,
  ukClientAreaLinks,
  ukGeneralLinks,
  ukPolicyLinks,
  ukTopServicesLinks
} from "./data";
import Link from "next/link";
import {UK, US, CA, UAE, DEFAULT, DEFAULT_LOCALES_LIST} from "../../helper/constantHelper";
import { Col, Row } from 'react-bootstrap';

function Footer(props) {
  const router = useRouter();
  const currYear = new Date().getFullYear();
  const {currLocale,linkLocale} = props;

  const [showOfferBanner, setShowOfferBanner] = useState(false);
  const [isNotExpired, setIsNotExpired] = useState(false);
  const [dropDownValue, setDropDownValue] = useState("Global");

  const changeValue = (event) => {
    setDropDownValue(event.currentTarget.textContent);
  }

  const [dropdownOpen, setDropDownOpen] = useState(false);
  const toggleFunc = () => {
    setDropDownOpen(prevState => !prevState);
  }

  const getLanguages = [
    { localeVal: DEFAULT, langLink: "/", langLabel: "Global" },
    { localeVal: UK, langLink: `/${UK}`, langLabel: "UK" },
    { localeVal: CA, langLink: `/${CA}`, langLabel: "Canada" },
    { localeVal: UAE, langLink: `/${UAE}`, langLabel: "UAE" }
  ];

  const languageDropDown = () => {
    return getLanguages.map(({localeVal, langLink, langLabel}) => {
      return localeVal !== currLocale &&
        <div className="dropdown-item" key={localeVal} onClick={changeValue}>
          <a href={langLink}>{langLabel}</a>
        </div>
    })
  }

  const getFooterLinks = (linksData) => {
    return linksData.map(({ title, href, noLocale }) => (
      <li key={href}>
        <Link
          href={href}
          locale={noLocale ? false : linkLocale}
          className="footer-link">{title}</Link>
      </li>
    ));
  }

  useEffect(() => {
    const selectedLanguage = getLanguages.find((item) => {
      return currLocale === item.localeVal;
    });
    const langVal = selectedLanguage ? selectedLanguage.langLabel : "Global";
    setDropDownValue(langVal);
  }, [currLocale]);

  const [bannerImage, setBannerImage] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [ctaText, setCtaText] = useState("");
  const [ctaLink, setCtaLink] = useState("");
  const [bannerActive, setBannerActive] = useState(false);
  const [isTestSite, setIsTestSite] = useState(0);
  const [bannerBackground, setBannerBackground] = useState("#000000");

  const isOrderPage = router.asPath.includes('?') ? (router.asPath.split('?')[0] === '/order') : (router.asPath === '/order');

  useEffect(() => {

      const locale = currLocale && currLocale === DEFAULT ? US : currLocale;

      ukApiHelper("websiteBanner", "GET", null, null, locale).then((res) => {
        const { banner_image, banner_text, cta_text, cta_link, date_start, date_end, background_color, status, test_site } = res.data.data;

        if (status) {
          setBannerImage(banner_image);
          setBannerText(banner_text);
          setCtaText(cta_text);
          setCtaLink(cta_link);
          setBannerBackground(background_color);
          setIsTestSite(test_site);
          setBannerActive(status !== 0);
          confirmBannerExpiry(date_start, date_end, status)

          sessionStorage["removeOfferBanner"] && !!sessionStorage["removeOfferBanner"] && !!isNotExpired ? setShowOfferBanner(false) : setShowOfferBanner(true);
        }

      }).catch((error) => console.error(`Error: ${error}`));


  }, []);

  const confirmBannerExpiry = (date_start, date_end, status) => {
    const startDate = new Date(new Date(date_start).toLocaleString("en-US", { timeZone: 'America/New_York' })).getTime();
    const calculatedEndDate = new Date(date_end);
    calculatedEndDate.setDate(new Date(date_end).getDate() + 1);
    const endDate = new Date(new Date(calculatedEndDate).toLocaleString("en-US", { timeZone: 'America/New_York' })).getTime();

    const today = new Date(new Date(Date.now()).toLocaleString("en-US", { timeZone: 'America/New_York' })).getTime();

    if (startDate < endDate && today >= startDate && today <= endDate && status !== 0) {
      setIsNotExpired(true);
    } else {
      setIsNotExpired(false);
    }
  }

  const removeOfferBanner = () => {
    sessionStorage["removeOfferBanner"] = true;
    setShowOfferBanner(false);
  }

  const isBannerInLiveSite = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? 0 : 1;

  let footerClassName = "";
  const shouldFooterBannerShow = showOfferBanner && isNotExpired && bannerActive && (isBannerInLiveSite === isTestSite);

  if (router.pathname === "/order") {
    footerClassName = `${styles.footer} ${!isOrderPage && shouldFooterBannerShow ? styles.footerBanner : ''}`;
  } else {
    footerClassName = `${styles.footer} ${shouldFooterBannerShow ? styles.footerBanner : ''}`;
  }

  return <>
    <footer className={footerClassName}>
      <div className="container">
        <div className={styles.footerTop}>
          <div className="row align-items-center">
            <div className="col-md-4">
              <Dropdown isOpen={dropdownOpen} toggle={toggleFunc}
                className={styles.languageDropdown}>
                <Dropdown.Toggle id={styles.languageDropdownToggle}>
                  {dropDownValue}
                  <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-8mmkcg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" /></svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.dropdownMenu}>
                  {languageDropDown()}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
            </div>
            <div className="col-md-4">
              <div className={styles.download}>
                <ul className="d-flex justify-content-end align-items-center">
                  <li>
                    <a className="app-link" href="https://apps.apple.com/us/app/cheapest-custom-writing-papers/id1447217562" target="_blank" rel="noreferrer">
                      <Image src="/app-store.webp" alt="app store" width={146} height={45} title="Appstore" />
                    </a>
                  </li>
                  <li className="me-0">
                    <a className="app-link" href="https://play.google.com/store/apps/details?id=com.cheapestessay.service" target="_blank" rel="noreferrer">
                      <Image src="/google-play.webp" alt="google play" width={146} height={45} title="Google Play" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.footerPayment} d-flex align-items-center flex-wrap`}>
          <Image
            src={stripImg}
            alt="Stripe"
            title="Stripe"
            width={380}
            height={30}
          />

          <Image
            src={paymentOption}
            alt="Payments"
            title="Payments Options"
            width={488}
            height={30}
          />

        </div>
        <div className={styles.footerCenter}>
          <Row>
            <Col sm={6} md={3}>
              <div className={styles.footerWidget}>
                <div className={styles.title}>General</div>
                <ul>
                  {getFooterLinks(DEFAULT_LOCALES_LIST.includes(currLocale) ? generalLinks : ukGeneralLinks)}
                </ul>
              </div>
            </Col>
            <Col sm={6} md={3}>
              <div className={styles.footerWidget}>
                <div className={styles.title}>Client Area</div>
                <ul>
                  {getFooterLinks(DEFAULT_LOCALES_LIST.includes(currLocale) ? clientAreaLinks : ukClientAreaLinks)}
                </ul>
              </div>
            </Col>
            <Col sm={6} md={3}>
              <div className={styles.footerWidget}>
                <div className={styles.title}>Policy</div>
                <ul>
                  {getFooterLinks(DEFAULT_LOCALES_LIST.includes(currLocale) ? policyLinks : ukPolicyLinks)}
                </ul>
              </div>
            </Col>
            <Col sm={6} md={3}>
              <div className={styles.footerWidget}>
                <div className={styles.title}>Top Services</div>
                <ul>
                  {getFooterLinks(DEFAULT_LOCALES_LIST.includes(currLocale) ? topServicesLinks : ukTopServicesLinks)}
                </ul>
              </div>
            </Col>
            <Col className={styles.footerInfo} sm={6} md={3}>
              <div className={styles.footerWidget}>
                <div className={styles.title}>Contact</div>
                <ul className={styles.contactList}>
                  <li><a className="footer-link" href="tel:+19094411414" target="_blank" rel="noreferrer">Text &amp; Call: +1 (909) 441-1414</a></li>
                  <li><a className="footer-link" href="mailto:support@cheapestessay.com" target="_blank" rel="noreferrer">Email: Support@CheapestEssay.com</a></li>
                  <li><a className="footer-link" href="https://api.whatsapp.com/send?phone=19094411414" target="_blank" rel="noreferrer">WhatsApp: +1 (909) 441-1414</a></li>
                  <li><a className="footer-link" href="https://goo.gl/maps/FWiQ33fegs92" target="_blank" rel="noreferrer">Location: Columbus, OH 43229</a></li>
                </ul>
              </div>
              <ul className={`${styles.Fsocial} connection d-flex`}>
                {
                  socialLinks.map(({ href, title, src }, index) => (
                    <li key={href}>
                      <a className="social-link" href={href} target="_blank" rel="noreferrer">
                        <span><Image src={src} alt={title} width={20} height={20} title={title} /></span>
                      </a>
                    </li>
                  ))
                }
              </ul>
            </Col>
          </Row>
        </div>
        <div className={styles.copyright}>
          <div className='row'>
            <div className={`col-md-6 ${styles.copytext}`}>
              <p className="mb-0">
                Copyright &copy; {currYear}. www.CheapestEssay.com. All rights reserved.
              </p>
            </div>
            <div className={`col-md-6 ${styles.dmcicon}`}>
              <div className="d-flex justify-content-md-end">
                <a className="footer-link me-2" rel="noreferrer" href="https://www.dmca.com/Protection/Status.aspx?ID=c9e0d5bb-c1dc-49c1-9612-a7a8ccf78800&refurl=https://www.cheapestessay.com/" target="_blank" title="DMCA.com Protection Status">
                  <Image src={dmca} alt="DMCA.com Protection Status" width={121} height={25} title="DMCA" />
                </a>
                <a className="footer-link ms-2" rel="noreferrer" href="https://www.copyrighted.com/website/Jtx7WAUqoPwLf5yx" target="_blank" title="Copyrighted.com Registered & Protected">
                  <Image src={copyrightImg} alt="Copyrighted.com Registered &amp; Protected" width={125} height={25} title="Copyright" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showOfferBanner && !!isNotExpired && !isOrderPage && (isBannerInLiveSite === isTestSite) && bannerActive ?
        <div className={`${styles.offerBanner} ${showOfferBanner && !!isNotExpired && !isOrderPage && ((bannerText && bannerText !== '') || (ctaText && ctaText !== '') || (ctaLink && ctaLink !== '')) ? styles.visible : ''}`} style={{backgroundColor: bannerBackground && bannerBackground !== '' ? bannerBackground : '#000000'}}>
          <div className="container">
            <div className={styles.offerContentWrapper}>
              <div className={`${styles.offerContent} d-flex align-items-center flex-row justify-content-center`}>
                {bannerImage && bannerImage !== '' ?
                  <div className={styles.offerImage}>
                    <Image src={`${bannerImage}`} alt="Offer" width={110} height={60} title="Offer" />
                  </div>
                  : ''}

                {bannerText && bannerText !== '' ?
                  <div className={styles.offerTextWrapper}>
                    <span className={styles.offerMsg}>{parse(bannerText)}</span>
                  </div>
                  : ''}

                {ctaText && ctaText !== '' ?
                  <Link
                    href={`${ctaLink && ctaLink !== '' ? ctaLink : '#'}`}
                    passHref
                    className={`btn secondary-btn ${styles.orderBtn}`}
                    id="promotional-banner"
                    target="_blank">
                    {ctaText}
                  </Link>
                  : ''}
              </div>
              <div className={styles.close} onClick={removeOfferBanner}>
                <CloseIcon />
              </div>
            </div>
          </div>
        </div>
        : ''}
    </footer>
  </>;
}

export default Footer;