export const socialLinks = [
    {
        href: "https://www.facebook.com/CheapestEssay/",
        title: "Facebook",
        src: "/facebook.svg"
    },
    {
        href: "https://twitter.com/CheapestEssay",
        title: "Twitter",
        src: "/twitter.svg"
    },
    {
        href: "https://www.instagram.com/cheapestessay_official/",
        title: "Instagram",
        src: "/instagram.svg"
    },
    {
        href: "https://www.youtube.com/@cheapestessayblog9516",
        title: "Youtube",
        src: "/youtube.svg"
    },
    {
        href: "https://www.linkedin.com/company/cheapest-essay",
        title: "Linkedin",
        src: "/linkedin.svg"
    }
];

export const generalLinks = [
    { title: "Essay Writing", href: "/" },
    { title: "Our Services", href: "/services" },
    { title: "Top Writers", href: "/top-writers" },
    { title: "Coupons", href: "/coupons" },
    { title: "Essay Reviews", href: "/reviews" },
    { title: "Articles", href: "/articles" },
    { title: "Essay Examples", href: "/examples" },
    { title: "Student Discount", href: "/student-discount" }
];

export const ukGeneralLinks = [
    { title: "Essay Writing", href: "/" },
    { title: "Top Writers", href: "/top-writers" },
    { title: "Essay Reviews", href: "/reviews" },
    { title: "Articles", href: "/articles", noLocale: true },
    { title: "Essay Examples", href: "/examples", noLocale: true },
    { title: "Sitemap", href: "/sitemap" }
];

export const clientAreaLinks = [
    { title: "About Us", href:"/about-us" },
    { title: "How it Works", href:"/how-it-works" },
    { title: "Pricing", href:"/price" },
    { title: "FAQs", href:"/faqs" },
    { title: "Referral Program", href:"/referral-program-terms" },
    { title: "Sitemap", href:"/sitemap" },
    { title: "Contact Us", href:"/contact-us" },
    { title: "Business Partnership", href: "/business-partnership" },
    { title: "Videos", href: "/video-listing" }
];

export const ukClientAreaLinks = [
    { title: "About Us", href:"/about-us" },
    { title: "How it Works", href:"/how-it-works" },
    { title: "Pricing", href:"/price" },
    { title: "Contact Us", href:"/contact-us" },
    { title: "Disclaimer", href:"/disclaimer", noLocale : true },
    { title: "Rules of Conduct", href:"/rules-of-conduct", noLocale: true }
];

export const policyLinks = [
    { title: "Terms of Use", href:"/terms-of-use", noLocale : true },
    { title: "Privacy Policy", href:"/privacy-policy", noLocale : true },
    { title: "Revision Policy", href:"/revision-policy", noLocale : true },
    { title: "Cookie Policy", href:"/cookie-policy", noLocale : true },
    { title: "Guarantee", href:"/guarantees", noLocale : true },
    { title: "Money Back Guarantee", href:"/money-back-guarantee", noLocale : true },
    { title: "Rules of Conduct", href:"/rules-of-conduct", noLocale : true },
    { title: "Disclaimer", href:"/disclaimer", noLocale : true }
];

export const ukPolicyLinks = [
    { title: "Terms of Use", href:"/terms-of-use", noLocale : true },
    { title: "Privacy Policy", href:"/privacy-policy", noLocale : true },
    { title: "Revision Policy", href:"/revision-policy", noLocale : true },
    { title: "Cookie Policy", href:"/cookie-policy", noLocale : true },
    { title: "Guarantee", href:"/guarantees", noLocale : true },
    { title: "Money Back Guarantee", href:"/money-back-guarantee", noLocale : true }
];

export const topServicesLinks = [
    { title: "Buy Essay", href: "/buy-essay" },
    { title: "Same Day Essay", href: "/same-day-essay-writing-service" },
    { title: "Assignment Writing", href: "/assignment-writing-service" },
    { title: "Write Research Paper", href: "/write-my-research-paper" },
    { title: "Coursework Help", href: "/coursework-help-online" },
    { title: "Case Study Help", href: "/case-study-help" },
    { title: "Write My Speech", href: "/write-my-speech" }
];

export const ukTopServicesLinks = [
    { title: "Custom Essay", href:"/custom-essay" },
    { title: "Research Paper", href:"/research-paper-writing-services" },
    { title: "Coursework Writing", href:"/coursework-writing-services" },
    { title: "Case Study Writing", href:"/case-study-writing" },
    { title: "Assignment Writing", href:"/assignment-writing-service" },
    { title: "Dissertation Help", href:"/dissertation-help" }
];